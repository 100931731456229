<template>
  <div
    v-if="showBanner"
    class="mb-7"
  >
    <div 
      class="kyc-bar"
      @click="$router.push({name:'kyc'})"
    >
      Click here to complete your KYC and start trading »
    </div>
  </div>
</template>
<script>
import { useUsersStore } from '@/stores/users'

export default {
  name: 'KycBarModule',
  setup() {
    const usersStore = useUsersStore()
    return {usersStore}
  },
  computed: {
    isLoggedIn() {
      return !(this.usersStore.user.profile_type === 'App\\Models\\GuestUser')
    },
    isKycPending () {
      return this.usersStore?.user?.is_kyc_pending
    },
    tradingAccountStatus() {
      return this.usersStore.user.trading_status ?? null
    },
    showBanner() {
      const status = this.tradingAccountStatus;
      return this.isLoggedIn && this.usersStore?.user?.is_kyc_pending && !['A', 'R'].includes(status);
    },
  }
}
</script>

<style lang="scss">
 .ios-padding {
   padding-top: 36px !important;
 }
 .kyc-bar {
  background-color: #FF453A;
  color: white;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  position: fixed; 
  top: 0;
  width: 100%;
  z-index: 9999;
}
</style>