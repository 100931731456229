<template>
  <Bubble
    v-if="!tooltip"
    v-show="activeBubbles > 0"
    small
    class="mx-2"
    :font-size="fontSize"
    background="bg-custom-orange-400"
    :color="'text-white'"
  >
    {{ activeBubbles }}
  </Bubble>
  <div v-else>
    <Tooltip
      v-if="activeBubbles > 0 || companiesBubble"
      :position="position"
      :carrot="carrot"
      :closable="closable"
      @close="closable ? $emit('close', $event) : false"
    >
      <slot />
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from './_components/Tooltip.vue'
import { useUsersStore } from "@/stores/users"

export default {
  name: 'BubbleModule',
  components: {
    Tooltip
  },
  props: {
    type: {
      type: String,
      default: '',
      required: true
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: '-top-6 right-0'
    },
    carrot: {
      type: String,
      default: 'right'
    },
    fontSize: {
      type: String,
      default: 'text-2xs'
    },
    companiesBubble : {
      type: Boolean,
      default: false
    },
    group: {
      type: Array,
      default: () => []
    }
  },
emits: [
'close'
],
  setup() {
    const usersStore = useUsersStore()

    return { usersStore }

  },
  computed: {
    activeBubbles () {
      // if (!this.usersStore.user || !this.usersStore.user.profile || !this.usersStore.user.profile.bubbles) {
      //   return 0
      // }

      // const bubbles = this.usersStore.user.profile.bubbles.filter(bubble => {
      //   const tags = bubble.tags.split(',')
      //   if (this.group.length > 0) {
      //     return this.group.filter(g => {
      //       if (tags.includes(this.type + g)) {
      //         return true
      //       } else {
      //         return false
      //       }
      //     }).length > 0
      //   } else {
      //     return tags.includes(this.type)
      //   }
      // })

      // return bubbles.reduce((sum, current) => { return sum + current.count }, 0)
      return this.usersStore.user?.profile?.notification_count ?? 0;
    }
  }
}
</script>
