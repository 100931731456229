import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("pinpoint")
import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import i18n from "../js/language"

export const usePinpointStore = defineStore("pinpoint", {
  state: () => ({
    companyEstimateData: {},
    averageEstimateInfo: {},
    companyFinancials: {},
    userEstimateData: [],
    userEstimateDataAll: {},
    loading: false,
    saveLoading: false,
    loadingMore: false,
    nextPageUrl: "",
  }),
  actions: {
    async fetchCompanyEstimates(payload) {
      this.loading = true
      try {
        const response = await repository.fetchCompanyEstimateData(payload)
        // console.log('test pinpoint response', response)
        if (response.status === 200 && response.data) {
          
          this.companyFinancials = response.data.financial
          this.companyEstimateData = response.data.pinpoint
        } 
      }
      catch (e) {
        console.error(e)
        this.loading = false
        if(e.response.status === 404) this.reset()
      }
    },
    async fetchUserEstimates(userId, companyId) {

        this.loading = true
        try {
          const response = await repository.fetchUserEstimateData(userId)
          if (response.status === 200 && response.data) {
            if(companyId) {
              let sanitizedData = []
              response.data.map(item=> {
                if(item.company_id === companyId && item.year === this.companyEstimateData.estimateOn.year && item.quarter === this.companyEstimateData.estimateOn.quarter ) 
                  sanitizedData.push(item)
              })
              this.userEstimateData = [...sanitizedData]
            } else this.userEstimateDataAll = response.data
            this.loading = false
          } 
        }
        catch (e) {
          console.error(e)
          this.loading = false
        }
    },
    async updateCompanyEstimate(payload, id) {
        this.saveLoading = true
        try {
          // console.log('test $', payload)
          const response = await repository.updateCompanyEstimateData({data: payload, id: id})
          if (response.status === 200 && response.data.length > 0) {
            this.saveLoading = false 
            useSnackbarStore().snack({
              text: i18n.global.t('Estimation added successfully.'),
              type: 'success',
              show: true,
              autoclose: true
            })

            return 'success'
          }
        }
        catch (e) {
          this.saveLoading = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          }) 
        }
    },
    reset() {
      this.companyEstimateData = {}
      this.companyFinancials = {}

    },
  },
})
