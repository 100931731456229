<template>
  <div>
    <EmailVerification
      ref="EmailVerification"
      :pins="pins"
      :email="usersStore.user.email"
      :loading-otp="usersStore.loadingOTPCheck"
      :is-mobile="isMobile"
      :otp-error-message="otpErrorMessage"
      :verify-otp-error="usersStore.verifyOtpError"
      :resend-email-loading="usersStore.resendEmailLoading"
      @submit="pinVerification"
      @resend-otp="resendOtp"
      @go-back="logout"
      @paste-code="pasteCode"
      @reset-error="usersStore.setVerifyOtpError('')"
      @on-input="onPinInput"
      @on-backspace="onPinBackspace"
      @on-delete="onPinDelete"
    />
  </div>
</template>

<script>

import EmailVerification from './_components/EmailVerification.vue'
import { isMobile } from '@/js/helpers/mobile'
import { useUsersStore } from '../../stores/users'
import { useOnboardingsStore } from '../../stores/onboarding'
import { useSnackbarStore } from '../../stores/snackbar'

export default {
  name: 'EmailVerificationModule',
  components: {
    EmailVerification
  },
  props: {

    email: {
      type: String,
      default: ''
    },

    userType: {
      type: String,
      default: ''
    }
  },
  emits: [
    'resend-otp',
    'email-verified'
  ],
  setup () {
    const usersStore = useUsersStore()
    const onboardingStore = useOnboardingsStore()
    const snackbarStore = useSnackbarStore()
    return { usersStore, onboardingStore, snackbarStore }
  },
  data () {
    return {
      otpErrorMessage: '',
      editEmail: false,
      editedEmail: '',
      pins: ["", "", ""],
      pinDeleteMode: false
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  mounted () {
    if (this.process !== 'onboarding') {
      this.resendOtp()
    }
  },
  methods: {
    handleEditEmail () {
      if (this.editEmail === false) { this.editedEmail = this.usersStore.user.email }

      this.editEmail = !this.editEmail
    },
    saveEmail () {
      this.editEmail = false
      if (this.usersStore.user.email !== this.editedEmail) {
        this.usersStore.edit({
          id: this.usersStore.user.id,
          email: this.editedEmail,
          username: this.usersStore.user.profile.username,
          country: this.usersStore.user.country ? this.usersStore.user.country : null,
          process: 'verifyingEmail'
        })
      }
    },
    pasteCode (event) {
      this.usersStore.setVerifyOtpError('')
      let pastedPin = event.clipboardData.getData('text/plain')
      if (pastedPin.length > 0) {
        for (let i = 0; i < 3; i++) {
          this.pins[i] = pastedPin[i]
          this.$refs.EmailVerification.$refs[`pin${i}`][0].focus();
        }
      }
    },
    resendOtp () {
      if (this.process === 'onboarding') {
        this.$emit('resend-otp')
      } else if (this.process === 'secondary-email') {
        this.usersStore.sendSecondaryEmailVerification({ email: this.email })
      } else {
        this.usersStore.sendEmailVerification()
      }
    },
    onPinInput (payload) {
      this.usersStore.setVerifyOtpError('')

      // Update the pin array with the new value
      const value = payload.event.target.value;
      if (/^\d?$/.test(value)) {
        this.pins[payload.index] = value; // Only allow a single digit
        this.pinDeleteMode = false;
        // Move to the next input if there is a value and it is not the last input
        if (value && payload.index < this.pins.length - 1) {
          this.$refs.EmailVerification.$refs[`pin${payload.index + 1}`][0].focus();
        }
      }
    },
    onPinBackspace (index) {
      this.usersStore.setVerifyOtpError('')
      // Move to the previous input if backspace is pressed and the current input is empty
      if (this.pins[index] === "" && index > 0) {
        this.$refs.EmailVerification.$refs[`pin${index - 1}`][0].focus();
      }
    },
    onPinDelete (index) {
      this.usersStore.setVerifyOtpError('')
      if (index === 0 || index === 1) {
        if (this.pinDeleteMode) {
          // If delete mode is active, clear the next input
          if (index < this.pins.length - 1) {
            this.pins[index + 1] = "";
            this.pinDeleteMode = false; // Reset delete mode after deleting
            this.$refs.EmailVerification.$refs[`pin${index + 1}`][0].focus();
          }
        } else {
          // Activate delete mode and delete the current input
          this.pins[index] = "";
          this.pinDeleteMode = true; // Set delete mode to active
        }
      } else {
        // Normal delete behavior for other inputs
        this.pins[index] = "";
        this.pinDeleteMode = false; // Reset delete mode for other inputs
      }
    },
    async pinVerification () {
      let code = this.pins.join('')

      if(code.length !== 3){
        this.usersStore.setVerifyOtpError('Please enter a valid veification pin')
      }
      this.usersStore.otpVerification({ token: code, process: 'signupEmailVerification', userType: this.userType })
     
    },
    logout () {
      this.usersStore.logout()
    }
  }
}
</script>
