<template>
  <HeaderComponent
    v-if="showMenu"
    :user="user"
    :i-phone-margin="appStore?.iPhoneMargin"
    :is-verified="user && user.profile && user.profile.is_verified"
    :use-menu="useMenu"
    :show-extended-menu="showExtendedMenu"
    :show-notifications-menu="showNotificationsMenu"
    :show-profile-menu="showProfileMenu"
    :unread-messages="unreadMessages"
    :show-my-eucaps-menu="showMyEucapsMenu"
    :is-logged-in="isLoggedIn"
    @toggle-extended-menu="showExtendedMenu = !showExtendedMenu"
    @toggle-notifications-menu="showNotificationsMenu = !showNotificationsMenu"
    @toggle-profile-menu="toggleProfileMenu"
    @show-my-eucaps-menu="showMyEucapsMenu = !showMyEucapsMenu"
    @toggle-my-eucaps-menu="showMyEucapsMenu = false"
  />
</template>

<script>
import HeaderComponent from './_components/HeaderComponent'
import { isMobile } from '@/js/helpers/mobile'
import { useChatsStore } from '../../stores/chats'
import { useUsersStore } from '../../stores/users'
import { usePostsStore } from '../../stores/posts'
import { useAppsStore } from '../../stores/app'

export default {
  name: 'HeaderModule',
  components: {
    HeaderComponent
  },
  setup() {
    const chatsStore = useChatsStore()
    const usersStore = useUsersStore()
    const postsStore = usePostsStore()
    const appStore = useAppsStore()
    
    return { chatsStore, usersStore, postsStore, appStore }
  },
  data () {
    return {
      showExtendedMenu: false,
      showNotificationsMenu: false,
      showProfileMenu: false,
      showMyEucapsMenu: false,
    }
  },
  computed: {
    isLoggedIn() {
      return !(this.usersStore.user.profile_type === "App\\Models\\GuestUser")
    },
    unreadMessages() {
      if (!this.chatsStore.unreadMessages || this.chatsStore.unreadMessages.length === 0) return 0
      let messageCount = 0
      this.chatsStore.unreadMessages.forEach(chat => {
        if (chat) {
          messageCount += chat          
        }
       })
      return messageCount
    },
    showMenu () {
      return this.appStore.useHeader
    },
    allMessages() {
      return this.chatsStore.allMessages
    },
    generalChats() {
      return this.chatsStore.generalChats
    },
    user() {
      return this.usersStore.user
    },
    useMenu () {
      return isMobile()
    },
  },
  methods: {
    toggleProfileMenu () {
      this.showProfileMenu = !this.showProfileMenu
    }
  }
}
</script>
