import { RepositoryFactory } from '@/api/repositoryFactory'
import { defineStore } from 'pinia'
import mentions from '@/js/helpers/mentions'
import i18n from '../js/language'
import { useUsersStore } from './users'
import { useFiltersStore } from './filters'
import { useSnackbarStore } from './snackbar'
const repository = RepositoryFactory.get('posts')
const userRepository = RepositoryFactory.get('users')
import { useFiltrationsStore } from './filtrations'
import { useMessagesStore } from './messages'
import router from '../js/router'
const filterationRepository = RepositoryFactory.get('filtrations')

export const usePostsStore = defineStore('posts', {
  state: () => ({
    type: 'general-feed',
    loading: false,
    loadingNewPost: false,
    addPostLoading: false,
    pollLoading: false,
    selectedPost: '',
    originalNextPageUrl: {},
    originalPosts: {},
    loadingPostMenu: false,
    editableId: null,
    selectedPostId: '',
    loadingSponsored: false,
    loadingFollowers: false,
    loadingNewFollowers: false,
    followersNextPageUrl: null,
    followers: [],
    threads: [],
	searchThreadsSortable: "default",
    searchThreadsOrder: "asc"
  }),
  getters: {
    sponsoredPosts: (state) => state.originalPosts['sponsored'],
    posts: (state) => {
      if (!state.originalPosts || !state.originalPosts[state.type]) {
        return []
      }
      let posts = [...state.originalPosts[state.type]]
      if (useFiltersStore().filters?.company_id) {
        posts = posts?.filter((post) => post?.company && post?.company.id === useFiltersStore().filters?.company_id)
      }
      if (useFiltersStore().filters?.stock_user_id) {
        posts = posts.filter((post) => post?.stock_user_id === useFiltersStore().filters?.stock_user_id)
      }
      return posts
    },
    allPost: (state) => state.originalPosts[state.type],
    pinnedPost: (state) => state.originalPosts['pinned'],
    postsWithoutTypes: (state) => state.originalPosts,
    nextPageUrl(state) {
      return state.originalNextPageUrl[state.type]
    },
    hasPosts: (state) => state.posts.length > 0
  },
  actions: {
    async followStockUser(otherUserId) {
      try {
        const response = await userRepository.follow(otherUserId)
        if (response.status === 200 && response.data) {
          const findIndex = this.followers?.findIndex(stockUser => stockUser.id === response?.data?.id)
          if (findIndex > -1) {
            this.followers[findIndex] = response.data
            this.followers = [...this.followers]
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchFollowers (postId) {
      try {
        this.followers = []
        this.loadingFollowers = true
        const response = await repository.followers(postId)
        this.loadingFollowers = false
        if (response.status === 200 && response.data) {
          this.followers = response.data.data
          this.followersNextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        this.loadingFollowers = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async scrollFollowers() {
      try {
        if (this.followersNextPageUrl !== null && !this.loadingNewFollowers) {
          this.loadingNewFollowers = true
          const response = await repository.scroll(this.followersNextPageUrl)
          if (response.status === 200 && response.data.data) {
            response.data.data.map((response) => {
              const flag = this.followers.find((data) => {
                return response.id === data.id
              })
              if (!flag || flag === undefined) {
                this.followers.push(response)
              }
            })
            this.followersNextPageUrl = response.data.next_page_url
            this.loadingNewFollowers = false
          }
        }
      } catch (e) {
        this.loadingNewFollowers = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async resetSearchThreadsSortable() {
      this.setSearchThreadsSortable({
        sortable: "default",
        order: "asc",
      })
    },
    async setSearchThreadsSortable(payload) {
      if (payload.sortable) {
        this.searchThreadsSortable = payload.sortable
      }
      if (payload.order) {
        this.searchThreadsOrder = payload.order
      }
    },
    async fetchAllThreads () {
      this.setPageUrl(null)
      this.setPosts([])
      let payload = {
        filter:  useFiltrationsStore().filtrationPayload,
        resultType: "threads",
        resultSubType: "threads",
        resultLimit: 15
      }
      if (this.searchThreadsSortable !== 'default') {
            payload = {
              ...payload,
              resultOrderType: {
                type: this.searchThreadsSortable,
                order: this.searchThreadsOrder
              }
            }
          }
      try {
        this.loading = true
        const response = await filterationRepository.getResult(payload)

        this.loading = false
        if (response.status === 200 && response.data) {
          this.setPosts(response.data.data)
          this.setPageUrl(response.data.next_page_url)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scrollThreads () {
      try {
        if (this.nextPageUrl !== null && !this.loadingNewPost && !this.loading) {
          this.loadingNewPost = true
          let payload = {
			filter:  useFiltrationsStore().filtrationPayload,
			resultType: "threads",
			resultSubType: "threads",
			resultLimit: 15
          }
          if (this.searchThreadsSortable !== 'default') {
				payload = {
                  ...payload,
                  resultOrderType: {
					type: this.searchThreadsSortable,
					order: this.searchThreadsOrder
                  }
				}
              }
          const response = await repository.scroll(this.nextPageUrl, payload)
          if (this.searchThreadsSortable !== 'default') {
            payload = {
              ...payload,
              resultOrderType: {
                type: this.searchThreadsSortable,
                order: this.searchThreadsOrder
              }
            }
          }
          if (response.status === 200 && response.data) {
            this.appendPosts(response.data.data)
            this.setPageUrl(response.data.next_page_url)
          }
          this.loadingNewPost = false
        }
      } catch (e) {
        this.loadingNewPost = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setPageUrl(payload) {
      this.originalNextPageUrl = { ...this.originalNextPageUrl, [this.type]: payload }
    },
    appendPosts(payload) {
      if (payload.post) {
        payload = payload.post
      }
      payload.filter((post) => {
        if (post.type !== undefined) {
          if (post.type === 'share') {
            if (
              post.shared_post.shareable &&
              (typeof post.shared_post.shareable.message === 'string' ||
                post.shared_post.shareable.message instanceof String)
            ) {
              post.shared_post.shareable.message = mentions.parse(
                post.shared_post.shareable.message,
                post.shared_post.shareable.messages.mentions
              )
            }
          } else if (post.postable_type === 'App\\Models\\Media') {
            if (
              post.postable.mediable.message &&
              (typeof post.postable.mediable.message === 'string' ||
                post.postable.mediable.message instanceof String) &&
              post.postable.mediable.message.match(mentions.MATCHING)
            ) {
              post.postable.mediable.message = mentions.parse(
                post.postable.mediable.message,
                post.postable.messages.mentions
              )
            }
          } else if (
            post &&
            post.message && post.messages &&
            (typeof post.message === 'string' || post.message instanceof String) &&
            post.message.match(mentions.MATCHING)
          ) {
            post.message = mentions.parse(post.message, post?.messages?.mentions)
          }
          if (post.latest_comment.length > 0) {
            post.latest_comment.filter((comment) => {
              if (comment.text && comment.text.match(mentions.MATCHING)) {
                comment.text = mentions.parse(comment.text, comment.message.mentions)
              }
            })
          }
        } else {
          return true
        }
        
      })

      payload.find((response) => {
        const flag = this.originalPosts[this.type].findIndex((post) => {
          if (post.advertise_id !== undefined) {
            return false
          } else {
            return Number(response.id) === Number(post.id)
          }
        })
        if (flag > -1) {
          this.originalPosts[this.type][flag] = { ...response }
        } else {
          this.originalPosts[this.type].push(response)
        }
      })

      this.originalPosts = { ...this.originalPosts }
    },
    async follow (postId) {
      try {
        this.loadingNew = true
        const response = await repository.follow(postId)
        this.loadingNew = false
        if (response.status === 200 && response.data) {
          this.updatePost(response.data)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    updatePost(payload) {
      if (payload.type === 'share') {
        if (
          payload.shared_post.shareable &&
          (typeof payload.shared_post.shareable.message === 'string' ||
          payload.shared_post.shareable.message instanceof String)
        ) {
          payload.shared_post.shareable.message = mentions.parse(
            payload.shared_post.shareable.message,
            payload.shared_post.shareable.messages.mentions
          )
        }
      }
      if (
        payload.postable &&
        payload.postable.mediable &&
        (typeof payload.postable.mediable.message === 'string' || payload.postable.mediable.message instanceof String) &&
        payload.postable.mediable.message.match(mentions.MATCHING)
      ) {
        payload.postable.mediable.message = mentions.parse(
          payload.postable.mediable.message,
          payload.postable.message.mentions
        )
      }
      if (
        payload &&
        payload.message && payload.messages &&
        (typeof payload.message === 'string' || payload.message instanceof String) &&
        payload.message.match(mentions.MATCHING)
      ) {
        payload.message = mentions.parse(payload.message, payload.messages.mentions)
      }
      let posts = [...this.originalPosts[this.type]]
      let postIndex = posts.findIndex((p) => p.id === payload.id)
      if(payload.priority > 1){
        posts.splice(postIndex, 1 )
        posts =[payload, ...posts]  
      }
      else{
        posts.splice(postIndex, 1 )
        posts.push(payload)
      }
      this.originalPosts[this.type] = [...posts]
      this.originalPosts = { ...this.originalPosts }

      if (this.originalPosts['general-feed']) {
        let postsGeneral = [...this.originalPosts['general-feed']]
        postIndex = posts.findIndex((p) => p.id === payload.id)
        if (postIndex) {
          postsGeneral[postIndex] = payload
          this.originalPosts['general-feed'] = [...postsGeneral]
          this.originalPosts = { ...this.originalPosts }
        }
      }
    },
    setPinnedPosts(post) {
      if (
        post.postable &&
        post.postable.mediable &&
        (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) &&
        post.postable.mediable.message.match(mentions.MATCHING)
      ) {
        post.postable.mediable.message = mentions.parse(post.postable.mediable.message, post.postable.message.mentions)
      }
      if (
        post &&
        post.message &&
        (typeof post.message === 'string' || post.message instanceof String) &&
        post.message.match(mentions.MATCHING)
      ) {
        post.message = mentions.parse(post?.message, post?.messages?.mentions)
      }
      this.originalPosts = { ...this.originalPosts, pinned: [...post] }
      this.originalPosts = { ...this.originalPosts }
    },
    setPosts (payload) {
      payload.filter((post) => {
        if (post.type === 'share') {
          if (
            post.shared_post.shareable &&
            (typeof post.shared_post.shareable.message === 'string' ||
              post.shared_post.shareable.message instanceof String)
          ) {
            post.shared_post.shareable.message = mentions.parse(
              post.shared_post.shareable.message,
              post.shared_post.shareable.messages.mentions
            )
          }
        }
        if (
          post.postable &&
          post.postable.mediable &&
          (typeof post.postable.mediable.message === 'string' || post.postable.mediable.message instanceof String) &&
          post.postable.mediable.message.match(mentions.MATCHING)
        ) {
          post.postable.mediable.message = mentions.parse(
            post.postable.mediable.message,
            post.postable.message.mentions
          )
        }
        if (
          post &&
          post.message && post.messages &&
          (typeof post.message === 'string' || post.message instanceof String) &&
          post.message.match(mentions.MATCHING)
        ) {
          post.message = mentions.parse(post?.message, post?.messages?.mentions)
        }
        if (post.latest_comment.length > 0) {
          post.latest_comment.filter((comment) => {
            if (comment.text && comment.text.match(mentions.MATCHING)) {
              comment.text = mentions.parse(comment.text, comment.message.mentions)
            }
          })
        }
      })
      const type = payload?.type ? payload?.type : this.type
      this.originalPosts = { ...this.originalPosts, [type]: [...payload] }
      this.originalPosts = { ...this.originalPosts }
    },
    resetNextUrl() {
      this.setPageUrl(null)
    },
    async fetch(override = false) {
      if (
        !this.originalPosts ||
        !this.originalPosts[this.type] ||
        this.originalPosts[this.type].length === 0 ||
        override
      ) {
        this.setPageUrl(null)
        this.setPosts([])
        try {
          this.loading = true
          const response = await repository.list({
            ...useFiltersStore().filters,
            filter: useFiltersStore().sorting
          })
          this.loading = false
          if (response.status === 200 && response.data) {
            this.setPosts(response.data.data)
            this.setPageUrl(response.data.next_page_url)
          }
        } catch (e) {
          this.loading = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async fetchPost(payload) {
      try {
        // console.log('type omg', this.type)
        // if (this.originalPosts && this.originalPosts[this.type] && this.originalPosts[this.type].findIndex(post => post.id === postId) > -1) {
        // commit('SET_LOADING', false)
        // } else {
        // commit('RESET_POST_TYPE')
        if (payload.checkState === true && this.originalPosts && this.originalPosts[this.type]) {
          let post = this.originalPosts['general-feed'].find((post) => {
            return post.id === payload.postId
          })

          this.setPosts([post])
        } else {
          if (!payload.silent) {
            this.loading = true
          }
          const response = await repository.fetchPost(payload.postId)
          if (response.status === 200 && response.data) {
            if (!payload?.silent) {
              this.setPosts([response.data])
            } else {
              this.updatePost(response.data)
            }

            this.loading = false
          }
        }
        // }
      } catch (e) {
        this.loading = false
        this.router.go(-1)
        if (e.response.status === 404) {
          // alert('Oops, there was an error, the thread was not found')
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            text: 'The thread does not exist',
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async fetchSinglePost(payload) {
      try {
        const response = await repository.fetchPost(payload.postId)
          if (response.status === 200 && response.data) {
           return response.data

          }
        }
      catch (e) {
        this.loading = false
        if (e.response.status === 404) {
          // alert('Oops, there was an error, the thread was not found')
          useSnackbarStore().snack({
            type: 'error',
            text: 'Oops, there was an error, the thread was not found',
            show: true,
            autoclose: true
          })
          this.router.go(-1)
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async sponsoredPost(postId) {
      try {
        const response = await repository.sponsoredPost(postId)
        if (response.status === 200 && response.data) {
          this.appendPosts([response.data])
          return true
        } else if (response.status === 201) {
          return false
        }
      } catch (e) {
        // commit('SET_LOADING', false)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async unsponsoredPost(postId) {
      try {
        const response = await repository.unsponsoredPost(postId)
        if (response.status === 200 && response.data) {
          this.appendPosts([response.data])
          return true
        } else if (response.status === 201) {
          return false
        }
      } catch (e) {
        // commit('SET_LOADING', false)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async unPinPost(postId) {
      try {
        this.loading = true
        const response = await repository.unpinPost(postId)
        if (response.status === 200 && response.data) {
          this.updatePost(response.data)
          this.loading = false
        }
        // }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchPinnedPost() {
      try {
        if (
          this.originalPosts &&
          this.originalPosts['pinned'] &&
          this.originalPosts['pinned'].findIndex((post) => post.pinned)
        ) {
          console.log('')
        } else {
          // commit('RESET_POST_TYPE')
          const response = await repository.fetchPinnedPost()
          if (response.status === 200 && response.data) {
            this.setPinnedPosts(response.data)
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async editPost(payload) {
      try {
        this.editableId = payload.postId
        const response = await repository.update(payload.postId, payload.data)
        this.editableId = null

        if (response.status === 200) {
          const posts = JSON.parse(localStorage.getItem('posts')) || [];
          const postIndex = posts.findIndex(post => post.id == payload.postId);
          if (postIndex !== -1) {
            posts[postIndex] = response.data;
            localStorage.setItem('posts', JSON.stringify(posts));
            useMessagesStore().newPosts = posts;
          } else {
            console.error('Post not found');
          }
          this.appendPosts([response.data])

          useSnackbarStore().snack({
            text: i18n.global.t('Thread updated successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
        this.addPostLoading = false
      } catch (e) {
        this.addPostLoading = false
        this.editableId = null
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async savePost(postId) {
      try {
        const response = await repository.savePost(postId)
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: i18n.global.t('Post created successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    postLoading(boolean) {
      this.addPostLoading = boolean
    },
    setType(type) {
      this.type = type
    },
    async refetch() {
      try {
        if (!this.loading) {
          this.addPostLoading = true
          const response = await repository.list({
            ...useFiltersStore().filters,
            filter: useFiltersStore().sorting
          })
          this.addPostLoading = false
          if (response.status === 200 && response.data) {
            this.setPosts(response.data.data)
            this.setPageUrl(response.data.next_page_url)
          }
        }
      } catch (e) {
        this.addPostLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scroll() {
      // console.log('getters', getters)
      try {
        if (this.nextPageUrl !== null && !this.loadingNewPost && !this.loading) {
          this.loadingNewPost = true
          const response = await repository.scroll(this.nextPageUrl, {
            ...useFiltersStore().filters,
            filter: useFiltersStore().sorting
          })
          if (response.status === 200 && response.data) {
            this.appendPosts(response.data.data)
            this.setPageUrl(response.data.next_page_url)
          }
          this.loadingNewPost = false
        }
      } catch (e) {
        this.loadingNewPost = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async postFilter(payload) {
      try {
        this.loading = true
        const response = await repository.filter(payload)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.setPosts(response.data.data)
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async saveOption(payload) {
      try {
        this.pollLoading = true
        const response = await repository.saveOption(payload.post_poll_id, {
          options: JSON.stringify(payload.options)
        })
        if (response.status === 200) {
          const index = this.originalPosts[this.type].findIndex((post) => {
            return post.id === response.data.room_post_id
          })
          this.originalPosts[this.type][index].post_poll.answers = response.data.answers
          useSnackbarStore().snack({
            text: 'Options added successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
          this.pollLoading = false
        }
      } catch (e) {
        this.pollLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async pollAnswer(payload) {
      try {
        const stockUser = useUsersStore().user.profile
        let removeIndex

        const type = payload.type ? payload.type : this.type

        const postIndex = this.originalPosts[type].findIndex((post) => {
          if (post.post_poll && post.post_poll.answers) {
            return post.id === payload.post_id
          }
        })

        const pollIndex = this.originalPosts[type][postIndex].post_poll.answers.findIndex((answer) => {
          return answer.id === payload.answer_id
        })

        const answerIndex = this.originalPosts[type][postIndex].post_poll.answers.findIndex((answer) => {
          if (answer.voters) {
            removeIndex = answer.voters.findIndex((voter) => {
              return voter.id === stockUser.id
            })
          }
          return removeIndex > -1
        })

        if (this.originalPosts[type][postIndex].post_poll.answers[answerIndex] !== undefined) {
          this.originalPosts[type][postIndex].post_poll.answers[answerIndex].voters.splice(removeIndex, 1)
        }

        this.originalPosts[type][postIndex].post_poll.answers[pollIndex].voters.push({
          companies_count: stockUser.companies_count,
          created_at: stockUser.created_at,
          username: stockUser.username,
          full_name: stockUser.full_name,
          id: stockUser.id,
          image: stockUser.image,
          pivot: {
            poll_answer_id: payload.answer_id,
            stock_user_id: stockUser.id
          },
          posts_count: stockUser.posts_count,
          reputation: stockUser.reputation
        })

        await repository.saveAnswer(payload.post_poll_id, payload.answer_id)
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareLike(payload) {
      try {
        const parentPost = this.originalPosts[this.type].find((timeline) => timeline.id === payload.parent_id)
        const post = parentPost.shared_post.shareable

        const likeIndex = post.likes.findIndex(
          (like) => like.stock_user_id === useUsersStore().user.profile_id && like.reaction === payload.reaction
        )

        if (likeIndex > -1) {
          post.likes.splice(likeIndex, 1)
        } else {
          post.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          })
        }

        await repository.postLike(payload.post_id, {
          reaction: payload.reaction
        })
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async like(payload) {
      try {
        const type = payload.type ? payload.type : this.type

        const post = this.originalPosts[type].find((timeline) => Number(timeline.id) === Number(payload.post_id))

        const likeIndex = post.likes.findIndex(
          (like) =>
            Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) && like.reaction === payload.reaction
        )

        if (likeIndex > -1) {
          post.likes.splice(likeIndex, 1)
        } else {
          post.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          })
        }

        await repository.postLike(payload.post_id, {
          reaction: payload.reaction
        })

        this.originalPosts = { ...this.originalPosts }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareToFeed(payload) {
      try {
        const response = await repository.shareToFeed(payload.post_id, {
          message: payload.message
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Shared to feed.',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async shareWithCompany(payload) {
      try {
        const response = await repository.shareWithCompany(payload.post_id, payload.company_id, {
          message: payload.message
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: 'Thread shared.',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async pushPost(post) {
      try {
        this.appendPosts([post])
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addPost(payload) {
      try {
        this.addPostLoading = true
        const response = await repository.store(payload)
        if (response.status === 200 && response.data) {
          useSnackbarStore().snack({
            text: 'Post created successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
          if (router.currentRoute._value.name === 'myFeed') {
            useMessagesStore().prependFeed(response.data);
          } else {
            this.fetch(true)
          }
		}
		this.addPostLoading = false
      } catch (e) {
		this.addPostLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async report(payload) {
      try {
        this.loadingPostMenu = true
        const response = await repository.reportPost(payload)

        this.loadingPostMenu = false
        if (response.status === 200) {
          useSnackbarStore().snack({
            text: 'Thread reported successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loadingPostMenu = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async delete(payload) {
      try {
        this.loadingPostMenu = true
        const response = await repository.deletePost(payload)

        this.loadingPostMenu = false
        if (response.status === 200) {
          this.originalPosts[this.type]?.splice(
            this.originalPosts[this.type]?.findIndex((post) => post.id === payload),
            1
          )

          useSnackbarStore().snack({
            text: 'Thread deleted successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loadingPostMenu = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    reset() {
      this.originalPosts = { ...{} }
    },
	async newPosts(payload) {
      try {
        const response = await repository.newPosts(payload)
        if (response.status === 200 && response.data) {
          localStorage.setItem('posts', JSON.stringify(response.data))
          useMessagesStore().newPosts = [...response.data]?.reverse()
          setTimeout(() => {
			localStorage.removeItem('posts');
          }, 300000)
          return true
        } else if (response.status === 201) {
          return false
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
  }
})
