<template>
  <div>
    <KycBarContainer />
    <div class="page flex flex-grow flex-shrink-0 flex-col bg-main-background dark:bg-custom-purple-500">
      <VersionBannerContainer />
      <CompleteCompanyOnboarding />
      <HeaderContainer
        class="w-full"
        :class="globalPadding"
      />
      <main
        class="flex flex-col flex-grow flex-shrink-0 z-10"
        :class="!insideCompanyPage ? 'mb-16' : ''"
      >
        <div v-if="showFiltrations && !isMobile">
          <FiltrationsModuleContainer />
        </div>
        <RouterView :key="$route.fullPath" />
      </main>
      <FooterContainer
        v-if="$route.meta.needLogin !== false"
        :padding="footerPadding"
      />
      <!-- <FairUsageModalContainer v-if="((user && user.id && user.profile_type === 'App\\Models\\GuestUser' || !user || !user.id) && $route.meta.needLogin !== false) || (!(user && user.id && user.profile_type !== 'App\\Models\\GuestUser') && $route.meta.needLogin !== false)" /> -->
      <InitialUserWizardModalContainer v-if="hasIntroBubble" />
      <AfterPremiumUserContainer v-if="hasYouAreNowPremiumBubble" />
      <FrontFooterContainer 
        v-if="!(isMobile && parentRoute !== 'chat')" 
      />
      <PortalTarget
        name="dialog"
        multiple
      />
      <!-- <VerifyModalContainer /> -->
    </div>
  </div>
</template>

<script>

import HeaderContainer from '@/modules/header'
import FooterContainer from '@/modules/footer'
import FrontFooterContainer from '@/modules/frontFooter'
import InitialUserWizardModalContainer from '@/modules/initialSwiperWizardModal'
import AfterPremiumUserContainer from '@/modules/afterPremiumUser'
import CompleteCompanyOnboarding from '@/modules/completeCompanyOnboarding'
// import VerifyModalContainer from '@/modules/verifyModal'
// import FairUsageModalContainer from '@/modules/fairUsageModal'
import { PortalTarget } from 'vue3-portal'
import socket from '@/js/socket'

import headerPaddingMixin from '@/mixins/headerPaddingMixin'
import VersionBannerContainer from '@/modules/versionBanner'
import { useUsersStore } from '@/stores/users'
import { useAppsStore } from '@/stores/app'
import { useNotificationsStore } from '@/stores/notifications'
import { useCompaniesStore } from '@/stores/companies'
import FiltrationsModuleContainer from '@/modules/filtrations'
import { isMobile } from '../js/helpers/mobile'
import KycBarContainer from '@/modules/kycBar'

export default {
  name: 'LoggedInLayout',
  components: {
    HeaderContainer,
    FooterContainer,
    FrontFooterContainer,
    PortalTarget,
    InitialUserWizardModalContainer,
    AfterPremiumUserContainer,
    // VerifyModalContainer,
    VersionBannerContainer,
    CompleteCompanyOnboarding,
    // FairUsageModalContainer,
    FiltrationsModuleContainer,
    KycBarContainer
  },
  mixins: [headerPaddingMixin],
  setup () {
    const usersStore = useUsersStore()
    const appsStore = useAppsStore()
    const notificationsStore = useNotificationsStore()
    const companiesStore = useCompaniesStore()

    return { usersStore, appsStore, notificationsStore, companiesStore }
  },
  computed: {
    isMobile () {
      return isMobile()
    },
    isLoggedIn() {
      return this.usersStore.isLoggedin
    },
    isKycPending () {
      return this.usersStore?.user?.is_kyc_pending
    },
    globalPadding () {
      if (!this.appsStore.newVersionAvailable) { // shall only change for header
        return this.platformPadding
      }

      return ''
    },
    iPhoneMargin(){
      return this.appStore?.iPhoneMargin
    },
    footerPadding () {
      return this.platformPadding
    },
    hasYouAreNowPremiumBubble () {
      if (!this.usersStore.user || !this.usersStore.user.profile || !this.usersStore.user.profile.bubbles || this.usersStore.user.profile.bubbles.length === 0) {
        return false
      }

      let hasYouAreNowPremiumBubble = false

      this.usersStore.user.profile.bubbles.map(b => {
        if (b.type === 'you_are_now_premium') {
          hasYouAreNowPremiumBubble = true
        }
      })

      return hasYouAreNowPremiumBubble
    },
    hasIntroBubble () {
      if (!localStorage.introBubbleSeen) {
        if (!this.usersStore.user || !this.usersStore.user.profile || !this.usersStore.user.profile.bubbles || this.usersStore.user.profile.bubbles.length === 0) {
          return false
        }
        if (this.usersStore.user.profile.bubbles && this.usersStore.user.profile.bubbles.length>0) {
          let hasIntroBubble = false
          this.usersStore.user.profile.bubbles.map(b => {
            if (b.type === 'initial_user_wizard') {
              hasIntroBubble = true
            }
          })

          return hasIntroBubble
        }
        
      }

      return false
    },
    parentRoute () {
      if (this.$route.matched.length > 1) {
        return this.$route?.matched[0]?.name
      }
      return this.$route.name
    },
    showFiltrations () {
      //console.log('this.parentRoute', this.parentRoute)
      return this.parentRoute === 'explore' || this.parentRoute === 'forumAndDiscussions' || this.parentRoute === 'history'
    }
  },
  mounted () {
    this.notificationsStore.fetch()
    if(this.isLoggedIn) this.companiesStore.fetchFollowedCompanies()
    socket.emit('fetch-online-users')
  }
}
</script>
