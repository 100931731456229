<template>
  <header
    class="bg-custom-purple-500 dark:bg-custom-purple-800 dark:text-primary-dark w-full z-60 py-5 sticky top-0 sm:static"
    :class="useMenu ? 'z-60' : ''"
  > 
    <div
      class="relative w-full"
      :class="[iPhoneMargin]"
    >
      <Container
        wide
        :padding="iosPadding ? 'pt-0 pb-4 px-6' : 'px-4 py-0'"
      >
        <nav class="relative flex items-center justify-between sm:py-2 mx-auto">
          <div
            class="flex items-center"
            :class="!useMenu ? '' : 'w-full justify-between'"
          >
            <Icon
              v-if="useMenu"
              name="menu"
              class="text-2xl flex cursor-pointer sm:pr-2 sm:pl-4 text-white z-60"
              @click="$emit('show-my-eucaps-menu')"
            />
            <LogoContainer
              eucaps
              class=" mr-5 lg:mr-8 sm:mr-4"
            />
            <div
              v-if="useMenu"
              class="relative  sm:pr-4"
              :class="showNotificationsMenu ? 'text-custom-orange-400' : 'text-white'"
            >
              <Icon
                name="bell"
                class="text-2xl cursor-pointer"
                @click="$emit('toggle-notifications-menu')"
              />
              <BubbleContainer
                type="global_menu_notification"
                font-size="text-3xs"
                class="absolute rounded-full top-0 right-0 -mr-1 font-semibold"
              />
              <MyEucapsMenuModalContainer
                v-if="showMyEucapsMenu && !useMenu"
                :show-my-eucaps-menu="showMyEucapsMenu"
                @toggle-my-eucaps-menu="$emit('toggle-my-eucaps-menu')"
              />
              <NotificationsMenu
                v-if="showNotificationsMenu && useMenu"
                @menu-clicked="$emit('toggle-notifications-menu')"
              />
            </div>
          </div>
          <div
            v-if="!useMenu"
            class="absolute left-1/2 transform -translate-x-1/2 z-20"
          >
            <TopTagSearchContainer />
          </div>
          <div class="hidden sm:block">
            <div class="flex items-center text-white font-medium">
              <!-- <RouterLink
              :to="{ name: 'generalfeed' }"
              class="mr-2 lg:mr-6 flex items-center"
              @click="$emit('reload-data')"
            >
              <Icon
                name="home-alt"
                class="text-xl mr-2 home-icon"
                :class="$route.name === 'generalfeed' ? 'text-custom-orange-400' : ''"
              />
              <span class="hidden lg:block">Talk & News</span>
            </RouterLink> -->
              <!-- <RouterLink
              :to="{ name: 'roomResults' }"
              class="lg:mr-6 flex items-center text-center"
              @click="$emit('reload-data')"
            >
              <span class="">Forum & discussions</span>
            </RouterLink> -->
              <!-- <RouterLink
              :to="{ name: 'generalfeed' }"
              class="mr-2 lg:mr-6 flex items-center"
              @click="$emit('reload-data')"
            >
              <span class="hidden lg:block">Timeline</span>
            </RouterLink> -->
              <!-- <RouterLink
              :to="{ name: 'exploreCompanies' }"
              class="mr-0 lg:mr-6 flex items-center"
            >
              <Icon
                name="compass"
                :class="$route.fullPath.includes('/explore') ? 'text-custom-orange-400' : ''"
                class="text-2xl mr-2"
              />
              <span class="hidden lg:block">Explore</span>
            </RouterLink> -->
              <div
                v-if="!useMenu"
                class="relative"
              >
                <div
                  class="p-1 w-10 h-10 flex justify-center items-center rounded-full lg:mr-4 cursor-pointer hover:text-custom-orange-400"
                  :class="showNotificationsMenu ? 'text-custom-orange-400' : 'text-white'"
                >
                  <BubbleContainer
                    type="global_menu_notification"
                    font-size="text-3xs"
                    class="font-semibold absolute top-0 left-0"
                  />
                  <Icon
                    name="bell"
                    class="text-2xl"
                    @click="$emit('toggle-notifications-menu')"
                  />
                </div>
                <NotificationsMenu
                  v-if="showNotificationsMenu"
                  @menu-clicked="$emit('toggle-notifications-menu')"
                />
              </div>
              <div
                v-if="!useMenu"
                class="relative"
              >
                <Bubble
                  v-if="unreadMessages > 0"
                  small
                  background="bg-custom-orange-400"
                  color="text-white"
                  font-size="text-3xs"
                  class="absolute rounded-full top-0 left-0 z-10 font-semibold"
                >
                  {{ unreadMessages }}
                </Bubble>
                <Avatar
                  class="cursor-pointer"
                  :size="30"
                  :stock-user="user.profile"
                  :use-premium="false"
                  small
                  @click="$emit('toggle-profile-menu')"
                />
                <ProfileMenu
                  v-if="showProfileMenu"
                  :profile="user.profile"
                  @toggle="$emit('toggle-profile-menu')"
                />
              </div>

            <!-- <RouterLink
              :to="{ name: 'myEucapsOverview' }"
              class="flex items-center"
            >
              <Avatar
                :size="30"
                :stock-user="user.profile"
                :use-premium="false"
                small
                :class="$route.fullPath.includes('/my-eucaps') ? 'border-2 border-custom-orange-400 rounded-full' : ''"
              />
            </RouterLink> -->
            <!-- <div
              class="relative p-1 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer hover:bg-white dark:hover:bg-custom-purple-400 hover:text-custom-purple-500"
              :class="showExtendedMenu ? 'bg-white text-custom-purple-500' : 'bg-transparent'"
              @click="$emit('toggle-extended-menu')"
            >
              <Bubble
                v-if="!isVerified"
                small
                class="absolute top-0 left-0 bg-custom-orange-400"
              />
              <DotMenu
                horizontal
              />
            </div> -->
            </div>
          </div>
        <!-- <MenuContainer class="w-10/12" /> -->
        </nav>
      </Container>
      <MyEucapsMenuModalContainer
        v-if="showMyEucapsMenu && useMenu"
        class="absolute sm:right-5 left-0 top-14 z-70"
        :show-my-eucaps-menu="showMyEucapsMenu"
        @toggle-my-eucaps-menu="$emit('toggle-my-eucaps-menu')"
      />
    </div>
  </header>
</template>

<script>

import LogoContainer from '@/modules/logo'

// import TopCompanySearchContainer from '@/modules/topCompanySearch'
import TopTagSearchContainer from '@/modules/topTagSearch'
// import AppsMenu from './AppsMenu'
import MyEucapsMenuModalContainer from '@/modules/myEucapsMenuModal'
import NotificationsMenu from './NotificationsMenu'
import BubbleContainer from '@/modules/bubble'
import ProfileMenu from './ProfileMenu'

// import MenuContainer from '@/modules/menu'

export default {
  name: 'HeaderInternal',
  components: {
    LogoContainer,
    // TopCompanySearchContainer,
    TopTagSearchContainer,
    MyEucapsMenuModalContainer,
    NotificationsMenu,
    BubbleContainer,
    ProfileMenu,
  },
  props: {
    iosPadding: { type: Boolean, default: false },
    isLoggedIn: { type: Boolean, default: false },
    isVerified: { type: Boolean, default: false },
    menu: { type: Boolean, default: false },
    useMenu: { type: Boolean, default: false },
    showExtendedMenu: { type: Boolean, default: false },
    showMyEucapsMenu: { type: Boolean, default: false },
    showNotificationsMenu: { type: Boolean, default: false },
    showProfileMenu: { type: Boolean, default: false },
    unreadMessages: { type: Number, default: 0 },
    iPhoneMargin: { type: String, default: '' },
    user: {
      type: Object,
      default: () => { }
    }
  },
  emits: [
    'reload-data',
    'toggle-notifications-menu',
    'toggle-extended-menu',
    'toggle-profile-menu',
    'show-my-eucaps-menu',
    'toggle-my-eucaps-menu'
  ],
  
}
</script>

<style lang="scss">
.home-icon {
  margin-top: -3px;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(75px);
}

.ios-slide-fade-enter-active {
  transition: all 0.2s ease;
}

.ios-slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.ios-slide-fade-enter,
.ios-slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(111px);
}
</style>
